import axios from "axios";

const GardenService = {
    createFindGardensDefaultParams: function() {
        return {
            filterByPhrase: false,
            filterBySector: false,
            phrase: '',
            sector: 'all',
            offset: 0,
            limit: 100,
            sortBy: 'name',
            sortDirection: 'asc'
        }
    },

    findGardens: function (params) {
        return axios.get('/api/garden', {
            params: {
                'filter-by-phrase': params.filterByPhrase,
                'filter-by-sector': params.filterBySector,
                'phrase': params.phrase,
                'sector': params.sector,
                'offset': params.offset,
                'limit': params.limit,
                'sort-by': params.sortBy,
                'sort-direction': params.sortDirection
            }
        });
    },
    
    getGardenDetails: function(gardenId) {
        if (!gardenId || typeof gardenId != 'string') {
            throw new Error('The garden id argument is not valid.')
        }

        return axios.get('/api/garden/getDetails/' + gardenId)
    },
    
    updateGarden: function(data) {
        if (!data) {
            throw new Error('Data argument is not valid.')
        }

        return axios.put('/api/garden/update', data)
    },
}

export default GardenService