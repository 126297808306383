import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { HomePage } from './pages/Home'
import { NewsPage } from './pages/News/index'
import { LoginPage } from './pages/Login'
import Routes from "./features/router/Routes";
import {Files} from "./pages/Files";
import UploadFile from "./pages/Files/UploadFile";
import EditFile from "./pages/Files/EditFile";
import EditNewsPage from "./pages/News/EditNewsPage";
import EditHtmlTextItemInNewsPage from "./pages/News/Content/Item/EditHtmlTextItemInNewsPage";
import {NoticesPage} from "./pages/Notices";
import EditNoticePage from "./pages/Notices/EditNoticePage";
import EditHtmlTextItemInNoticePage from "./pages/Notices/Content/Item/EditHtmlTextItemInNoticePage";
import {PageVersionsPage} from "./pages/Pages/Versions";
import EditPageVersionPage from "./pages/Pages/Versions/EditPageVersionPage";
import EditHtmlTextItemInPageVersionPage from "./pages/Pages/Versions/Content/Item/EditHtmlTextItemInPageVersionPage";
import AddImageItemInNewsPage from "./pages/News/Content/Item/AddImageItemInNewsPage";
import EditImageItemInNewsPage from "./pages/News/Content/Item/EditImageItemInNewsPage";
import AddImageItemInNoticePage from "./pages/Notices/Content/Item/AddImageItemInNoticePage";
import EditImageItemInNoticePage from "./pages/Notices/Content/Item/EditImageItemInNoticePage";
import AddImageItemInPageVersionPage from "./pages/Pages/Versions/Content/Item/AddImageItemInPageVersionPage";
import EditImageItemInPageVersionPage from "./pages/Pages/Versions/Content/Item/EditImageItemInPageVersionPage";
import {Parties} from "./pages/Parties";
import CreatePartyPage from "./pages/Parties/CreatePartyPage";
import EditPartyPage from "./pages/Parties/EditPartyPage";
import PartyDetailsPage from "./pages/Parties/PartyDetailsPage";
import CreateLoginPage from "./pages/Security/Logins/CreateLoginPage";
import EditLoginPage from "./pages/Security/Logins/EditLoginPage";
import UpdatePasswordPage from "./pages/Security/Logins/UpdatePasswordPage";
import {Meters} from "./pages/Meters";
import AddMeasurement from "./pages/Meters/AddMeasurement";
import {Gardens} from "./pages/Gardens";
import GardenDetailsPage from "./pages/Gardens/GardenDetailsPage";
import EditGardenPage from "./pages/Gardens/EditGardenPage";

const router = createBrowserRouter([
    {
        element: <App/>,
        children: [
            {
                path: Routes.Home,
                element: <HomePage/>
            },
            {
                path: Routes.Gardens.Index,
                element: <Gardens/>,
                children: [{
                    path: Routes.Gardens.Index + Routes.Gardens.Details,
                    element: <GardenDetailsPage/>,
                    children: [{
                        path: Routes.Gardens.Index + Routes.Gardens.Details + Routes.Gardens.Edit,
                        element: <EditGardenPage/>,
                    }]
                }]
            },
            {
                path: Routes.News.Index,
                element: <NewsPage/>,
                children: [
                    {
                        path: Routes.News.Index + Routes.News.Edit,
                        element: <EditNewsPage/>,
                        children: [
                            {
                                path: Routes.News.Index + Routes.News.Edit + Routes.Content.Item.EditHtmlTextItem,
                                element: <EditHtmlTextItemInNewsPage/>,
                            },
                            {
                                path: Routes.News.Index + Routes.News.Edit + Routes.Content.Item.AddImageItem,
                                element: <AddImageItemInNewsPage/>,
                            },
                            {
                                path: Routes.News.Index + Routes.News.Edit + Routes.Content.Item.EditImageItem,
                                element: <EditImageItemInNewsPage/>,
                            }
                        ]
                    }
                ]
            },
            {
                path: Routes.Notices.Index,
                element: <NoticesPage/>,
                children: [
                    {
                        path: Routes.Notices.Index + Routes.Notices.Edit,
                        element: <EditNoticePage/>,
                        children: [
                            {
                                path: Routes.Notices.Index + Routes.Notices.Edit + Routes.Content.Item.EditHtmlTextItem,
                                element: <EditHtmlTextItemInNoticePage/>,
                            },
                            {
                                path: Routes.Notices.Index + Routes.Notices.Edit + Routes.Content.Item.AddImageItem,
                                element: <AddImageItemInNoticePage/>,
                            },
                            {
                                path: Routes.Notices.Index + Routes.Notices.Edit + Routes.Content.Item.EditImageItem,
                                element: <EditImageItemInNoticePage/>,
                            }
                        ]
                    }
                ]
            },
            {
                path: Routes.PageVersions.Index,
                element: <PageVersionsPage/>,
                children: [
                    {
                        path: Routes.PageVersions.Index + Routes.PageVersions.Edit,
                        element: <EditPageVersionPage/>,
                        children: [
                            {
                                path: Routes.PageVersions.Index + Routes.PageVersions.Edit + Routes.Content.Item.EditHtmlTextItem,
                                element: <EditHtmlTextItemInPageVersionPage/>,
                            },
                            {
                                path: Routes.PageVersions.Index + Routes.PageVersions.Edit + Routes.Content.Item.AddImageItem,
                                element: <AddImageItemInPageVersionPage/>,
                            },
                            {
                                path: Routes.PageVersions.Index + Routes.PageVersions.Edit + Routes.Content.Item.EditImageItem,
                                element: <EditImageItemInPageVersionPage/>,
                            }
                        ]
                    }
                ]
            },
            {
                path: Routes.Session.Login,
                element: <LoginPage/>
            },
            {
                path: Routes.Files.Index,
                element: <Files/>,
                children: [
                    {
                        path: Routes.Files.Index + Routes.Files.Upload,
                        element: <UploadFile/>
                    },
                    {
                        path: Routes.Files.Index + Routes.Files.Edit,
                        element: <EditFile/>
                    }
                ]
            },
            {
                path: Routes.Parties.Index,
                element: <Parties/>,
                children: [
                    {
                        path: Routes.Parties.Index + Routes.Parties.Create,
                        element: <CreatePartyPage/>
                    },
                    {
                        path: Routes.Parties.Index + Routes.Parties.Edit,
                        element: <EditPartyPage/>
                    },
                    {
                        path: Routes.Parties.Index + Routes.Parties.Details,
                        element: <PartyDetailsPage/>,
                        children: [
                            {
                                path: Routes.Parties.Index + Routes.Parties.Details + Routes.Logins.Create,
                                element: <CreateLoginPage/>
                            },
                            {
                                path: Routes.Parties.Index + Routes.Parties.Details + Routes.Logins.Edit,
                                element: <EditLoginPage/>
                            },
                            {
                                path: Routes.Parties.Index + Routes.Parties.Details + Routes.Logins.UpdatePassword,
                                element: <UpdatePasswordPage/>
                            }
                        ]
                    }
                ]
            },
            {
                path: Routes.Meters.Index,
                element: <Meters/>,
                children: [
                    {
                        path: Routes.Meters.Index + Routes.Meters.AddMeasurement,
                        element: <AddMeasurement/>
                    }
                ]
            }
        ]
    }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store}>
          <RouterProvider router={router}>
              <App/>
          </RouterProvider>
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
