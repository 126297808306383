import {DefaultLayout} from "../../layouts/DefaultLayout";
import React, {useEffect, useState} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import {Outlet, useMatch, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import GardenService from "../../features/gardens/GardenService";
import InProgress from "../../components/progress/InProgress";
import FloatingAlert from "../../components/notifications/FloatingAlert";
import {useParams} from "react-router";
import {store} from "../../redux/store";
import {offsetChanged} from "../../features/gardens/list/gardenListSlice";
import GardenListDataManager from "../../features/gardens/list/GardenListDataManager";
import ErrorUtils from "../../utils/error/ErrorUtils";
import Routes from "../../features/router/Routes";

export default function EditGardenPage() {
    const navigate = useNavigate()
    const [name, setName] = useState('')
    const [area, setArea] = useState('0')
    const [inProgress, setInProgress] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const params = useParams()
    const locationMatch = useMatch(Routes.Gardens.Index + Routes.Gardens.Details + Routes.Gardens.Edit);

    useEffect(() => {
        if (!locationMatch) {
            return
        }
        setInProgress(true)

        GardenService.getGardenDetails(params.gardenId)
            .then(r => {
                setInProgress(false)
                setName(r.data.name)
                setArea(r.data.area.toString())
            })
            .catch(e => {
                console.error(e)
                setInProgress(false)
                setShowError(true)
                setErrorMessage(ErrorUtils.getMessage(e))
            })
    }, [params.gardensId, locationMatch])

    function createFormData() {
        return {
            name: {
                error: false,
                helperText: ''
            },
            area: {
                error: false,
                helperText: ''
            },
        }
    }

    const [formData, setFormData] = useState(createFormData())

    function validate() {
        const newFormData = createFormData()
        let isValid = true

        if (name.trim().length === 0) {
            newFormData.name.error = true
            newFormData.name.helperText = 'Nazwa nie może być pusta'
            isValid = false
        }

        if (area.trim().length === 0) {
            newFormData.area.error = true
            newFormData.area.helperText = 'Powierzchnia nie może być pustym ciągiem znaków'
            isValid = false
        } else if (isNaN(parseFloat(area)) || parseFloat(area) <= 0) {
            newFormData.area.error = true
            newFormData.area.helperText = 'Powierzchnia powinna być liczbą większą od zera'
            isValid = false
        }

        setFormData(newFormData)
        return isValid
    }

    function updateGarden() {
        if (!validate()) {
            return
        }

        setInProgress(true)

        GardenService.updateGarden({
            id: params.gardenId,
            name: name,
            area: parseFloat(area)
        }).then(() => {
            // TODO: dodac informacje o sukcesie
            setInProgress(false)
            store.dispatch(offsetChanged(0))
            GardenListDataManager.load()
            navigate('..')
        }).catch(err => {
            console.error(err)
            setInProgress(false)
            setShowError(true)
            setErrorMessage(ErrorUtils.getMessage(err))
        })
    }

    if (!locationMatch) {
        return <Outlet/>
    }

    return (
        <>
            <DefaultLayout>
                <div id="top">
                    <AppBar id="app-bar" position="sticky">
                        <Toolbar>
                            <IconButton size="large" color="inherit" edge="start"
                                        aria-label="Wróć" aria-haspopup="false"
                                        onClick={() => {
                                            navigate('..')
                                        }}>
                                <ArrowBackIcon/>
                            </IconButton>

                            <Typography variant="h1" sx={{flexGrow: 1}}>
                                Edycja działki
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </div>

                <div id="content-wrapper">
                    <div id="content">
                        <h1>Dane działki</h1>

                        <Box component="form" noValidate autoComplete="off" display="block">
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <TextField label="Nazwa" fullWidth={true}
                                               value={name}
                                               onChange={(e) => setName(e.target.value)}
                                               error={formData.name.error}
                                               helperText={formData.name.helperText}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField label="Powierzchnia" fullWidth={true}
                                               value={area}
                                               onChange={(e) => setArea(e.target.value)}
                                               error={formData.area.error}
                                               helperText={formData.area.helperText}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button type={"submit"} variant="contained" size="large" onClick={(e) => {
                                        updateGarden()
                                        e.preventDefault()
                                    }}>
                                        Zapisz
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </div>

                <FloatingAlert open={showError}
                               severity="error"
                               message={errorMessage}
                               onClose={() => setShowError(false)}/>
                <InProgress open={inProgress}/>
            </DefaultLayout>
        </>
    )
}